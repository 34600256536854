import typeIconsList from '@/utilities/types/IconsLIst';
import styles from './IconsList.module.scss';

import cn from 'classnames';
import Image from 'next/image';

interface IconsListProps {
  iconsList: typeIconsList[];
  className?: string;
}

const IconsList = (props: IconsListProps) => {
  const { iconsList, className } = props;

  return (
    <div className={cn(className, styles.root)}>
      <ul>
        {iconsList.map((listItem, index) => (
          <li key={index}>
            {listItem.icon && listItem.icon?.mediaItemUrl && (
              <i>
                <Image
                  alt={listItem.icon.altText}
                  className={styles.icon}
                  height={listItem.icon.mediaDetails.height || 20}
                  src={listItem.icon.mediaItemUrl}
                  width={listItem.icon.mediaDetails.width || 20}
                />
              </i>
            )}
            {listItem.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IconsList;
